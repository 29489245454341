// import { CommentOutlined, ShareAltOutlined, StarOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { DATA_PROVIDER, USER_TYPE } from 'power/types/enum';
import { useAuth } from 'providers/AuthProvider';
import { useIso } from 'providers/useIso';
import { FC } from 'react';
import classes from './HeaderTitle.module.scss';

interface IHeaderTitle {
  title: string;
}

const HeaderTitle: FC<IHeaderTitle> = ({ title }) => {
   const { isos, isUserType } = useAuth();
   const { iso, setIso } = useIso();

   document.title = title;

   const enableIso = (iso: DATA_PROVIDER) => {
      if (isUserType(USER_TYPE.INTERNAL)) return true;

      if (iso === DATA_PROVIDER.NYISO) return true;

      return false;
   };

   return (
      <div className={classes.header}>
         <div className={classes.headerLogo}>
            <img src="/img/powerdev_96_48.png" alt="PowerDev" />
         </div>
         <div className={classes.headerTitle}>{title}</div>
         <div className="header-action-buttons">
            {/* <div role="button" title="Share">
               <ShareAltOutlined />
            </div>
            <div role="button" title="Add a comment">
               <CommentOutlined />
            </div>
            <div role="button" title="Add to favorites">
               <StarOutlined />
            </div> */}
            {iso !== DATA_PROVIDER.Undefined && (
               <div role="button" title="ISO" style={{ width: 'auto' }}>
                  <Select
                     variant="borderless"
                     value={iso}
                     onChange={(value) => {
                        setIso(value);
                     }}
                     style={{ height: 48, width: 90, textAlign: 'center' }}
                  >
                     {isos.map((x) => (
                        <Select.Option key={x} value={x} disabled={!enableIso(x)}>
                           {DATA_PROVIDER[x]}
                        </Select.Option>
                     ))}
                  </Select>
               </div>
            )}
         </div>
      </div>
   );
};

export default HeaderTitle;
