import { BroadcastChannel } from 'broadcast-channel';
import { IUser } from 'power/types';
import { DATA_PROVIDER, SUBSCRIPTION_PACKAGE, USER_TYPE } from 'power/types/enum';
import { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';

type Props = {
  children?: ReactNode;
};

type IAuthContext = {
  authenticated: boolean;
  user: IUser;
  // iso: DATA_PROVIDER;
  isos: DATA_PROVIDER[];
  // setIso: (iso: DATA_PROVIDER) => void;
  isUserType: (userType: USER_TYPE) => boolean;
  hasPackage: (iso: DATA_PROVIDER, subscriptionPackage: SUBSCRIPTION_PACKAGE) => boolean;
  loggedIn: (newUser: IUser) => void;
  loggedOut: () => void;
};

const initialUser: IUser = {
   /* accountId: -1, */
   userId: -1,
   userName: '',
   firstName: '',
   lastName: '',
   userType: USER_TYPE.BASIC,
   packages: [],
   package_contents: [],
   jwtToken: '',
};

const initialValue: IAuthContext = {
   authenticated: false,
   user: initialUser,
   // iso: DATA_PROVIDER.Undefined,
   isos: [],
   hasPackage: () => false,
   isUserType: () => false,
   // setIso: () => {},
   loggedIn: (newUser: IUser) => {},
   loggedOut: () => {},
};

const AuthContext = createContext<IAuthContext>(initialValue);

const AuthProvider = ({ children }: Props) => {
   const [isos, setIsos] = useState<DATA_PROVIDER[]>([]);
   // const [iso, setIso] = useState<DATA_PROVIDER>(initialValue.iso);
   // Initializing an auth state with false value (unauthenticated)
   const [authenticated, setAuthenticated] = useState(initialValue.authenticated);
   const [user, setUser] = useState(initialValue.user);

   const loggedIn = useCallback(
      (newUser: IUser, broadcast = true) => {
         setUser(newUser);
         setAuthenticated(true);
         if (broadcast) broadcastChannel.current.postMessage({ action: 'loggedIn', user: newUser });
      },
      [setAuthenticated, setUser],
   );

   const loggedOut = useCallback(
      (broadcast = true) => {
         setAuthenticated(false);
         setUser(initialUser);
         if (broadcast) broadcastChannel.current.postMessage({ action: 'loggedOut' });
      },
      [setAuthenticated, setUser],
   );

   useEffect(() => {
      if (user.userId !== -1) {
      // get unique isos
         const userIsos = user.package_contents.map((p) => p[0]).filter((value, index, self) => self.indexOf(value) === index);
         setIsos(userIsos);
      // setIso(userIsos[0]);
      // if (userIsos.some((x) => x === DATA_PROVIDER.NYISO)) setIso(DATA_PROVIDER.NYISO);
      }
   }, [user]);

   const isUserType = useCallback((userType: USER_TYPE) => user.packages.some((p) => p === userType), [user.packages]);

   const hasPackage = useCallback(
      (iso: DATA_PROVIDER, subscriptionPackage: SUBSCRIPTION_PACKAGE) => {
         const userHasPackage = user.package_contents.some((p) => p[0] === iso && p[1] === subscriptionPackage);

         if (iso === DATA_PROVIDER.NYISO) return userHasPackage;

         // Disabled Iso selection for non-NYISO users
         if (isUserType(USER_TYPE.INTERNAL)) return userHasPackage;

         return false;
      },
      [isUserType, user.package_contents],
   );

   const broadcastChannel = useRef(new BroadcastChannel('auth'));
   useEffect(() => {
      // console.log('Auth BroadcastChannel API registered.');
      broadcastChannel.current.onmessage = ({ action, user }: { action: 'loggedIn' | 'loggedOut'; user?: IUser }) => {
      // console.log('Auth BroadcastChannel Message:', action, user ?? '');
         if (action === 'loggedIn' && user) loggedIn(user, false);
         else if (action === 'loggedOut') loggedOut(false);
      };
   }, [loggedIn, loggedOut]);

   return <AuthContext.Provider value={{ authenticated, user, isos, hasPackage, isUserType, loggedIn, loggedOut }}>{children}</AuthContext.Provider>;
};

const useAuth = () => useContext(AuthContext);

export { AuthContext, AuthProvider, useAuth };
