import { Checkbox, Flex, Radio, Select } from 'antd';
import { ApiClients } from 'ApiClients';
import Legend from 'power/map/Legend';
import { usePowerMap } from 'power/map/usePowerMap';
import { INodeMapPageQuery } from 'power/types';
import { DATA_PROVIDER, MARKET_TYPE, NODE_MAP_DATA_COMPONENT, NODE_TYPE, PERIOD_TYPE, POWER_NODE_LAYER_NAME, PRICE_COMPONENT, TBX_VALUE_TYPE, TXBX_COMPONENT, USER_TYPE } from 'power/types/enum';
import { useAuth } from 'providers/AuthProvider';
import { useIso } from 'providers/useIso';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import BaseSection from '../base/BaseSection';
import classes from './NodeLayersSection.module.scss';

const DISABLED_COMPONENTS_HUB_ZONE: POWER_NODE_LAYER_NAME[] = [POWER_NODE_LAYER_NAME.MCC, /* POWER_NODE_LAYER_NAME.TBX, */ POWER_NODE_LAYER_NAME.BASIS_RISK];

type Props = {
  mapId: string;
};

const NodeLayersSection: React.FC<PropsWithChildren<Props>> = ({ mapId }) => {
   const { iso } = useIso();
   const { nodeLayers, setNodeLayers } = usePowerMap();
   const { isUserType } = useAuth();

   const [zonalTypes, setZonalTypes] = useState<('Hub' | 'Zone')[]>(['Hub', 'Zone']);

   const layerOptions = useMemo(() => {
      const defaultLayerOptions = nodeLayers?.layerOptions ?? [];
      if (nodeLayers?.query.nodeType !== NODE_TYPE.IsoHub) return defaultLayerOptions;

      return defaultLayerOptions.filter((x) => !DISABLED_COMPONENTS_HUB_ZONE.includes(x));
   }, [nodeLayers?.layerOptions, nodeLayers?.query.nodeType]);

   const minmax = useMemo(() => {
      if (nodeLayers?.data.features === undefined || nodeLayers?.data.features.length === 0) return [0, 0];
      const prices = nodeLayers.data.features.map((f) => f.properties.value);
      const min = Math.min(...prices);
      const max = Math.max(...prices);
      return [min, max];
   }, [nodeLayers?.data.features]);

   const changeActive = useCallback(() => {
      setNodeLayers(
         (prev) => prev && {
            ...prev,
            active: !prev.active,
         },
      );
   }, [setNodeLayers]);

   const onSubmit = useCallback(() => {
      if (iso === DATA_PROVIDER.Undefined || !nodeLayers?.query) return;

      setNodeLayers((prev) => prev && { ...prev, loading: true, data: { type: 'FeatureCollection', features: [] } });
      ApiClients.getInstance()
         .mapNode({ ...nodeLayers?.query, provider: iso })
         .then((data) => {
            setNodeLayers((prev) => prev && { ...prev, loading: false, data });
         })
         .finally(() => {
            setNodeLayers((prev) => prev && { ...prev, loading: false });
         });
   }, [iso, nodeLayers?.query, setNodeLayers]);

   useEffect(() => {
      onSubmit();
   }, [onSubmit]);

   const setLayerOption = useCallback(
      (newLayerOption: POWER_NODE_LAYER_NAME) => {
         const newComponent: NODE_MAP_DATA_COMPONENT =
        newLayerOption === POWER_NODE_LAYER_NAME.LMP
           ? NODE_MAP_DATA_COMPONENT.LMP
           : newLayerOption === POWER_NODE_LAYER_NAME.MCC
              ? NODE_MAP_DATA_COMPONENT.MCC
              : newLayerOption === POWER_NODE_LAYER_NAME.TBX
                 ? NODE_MAP_DATA_COMPONENT.TBX
                 : newLayerOption === POWER_NODE_LAYER_NAME.BASIS_RISK
                    ? NODE_MAP_DATA_COMPONENT.BASIS_RISK
                    : newLayerOption === POWER_NODE_LAYER_NAME.BATTERY_SITING
                       ? NODE_MAP_DATA_COMPONENT.BATTER_SITING
                       : NODE_MAP_DATA_COMPONENT.LMP;

         setNodeLayers(
            (prev) => prev && {
               ...prev,
               layerOption: newLayerOption,
               active: true,
               query: { ...prev.query, component: newComponent },
            },
         );
      },
      [setNodeLayers],
   );

   const changeQuery = useCallback(
      (newQuery: Partial<INodeMapPageQuery>) => {
         setNodeLayers(
            (prev) => prev && {
               ...prev,
               query: { ...prev.query, ...newQuery },
            },
         );
      },
      [setNodeLayers],
   );

   if (!nodeLayers || layerOptions.length === 0) return null;

   return (
      <BaseSection
         mapId={mapId} active={nodeLayers?.active ?? false} setActive={changeActive}
         title="Historical Prices" defaultCollapsed={nodeLayers?.defaultCollapsed} loading={nodeLayers.loading}>
         <div className={classes.nodeLayerControl}>
            <Radio.Group
               buttonStyle="outline"
               size="small"
               value={nodeLayers.query.nodeType || NODE_TYPE.DraftNode}
               onChange={(e) => {
                  if (e.target.value === NODE_TYPE.IsoHub) {
                     changeQuery({ nodeType: e.target.value, tbxValueType: TBX_VALUE_TYPE.Average });
                     // if(!layerOptions.includes(nodeLayers.layerOption))
                     setLayerOption(POWER_NODE_LAYER_NAME.LMP);
                  } else changeQuery({ nodeType: e.target.value });
               }}
               style={{ width: '100%' }}
            >
               <Flex
                  vertical={false} justify="space-around" style={{ padding: '8px 0px 4px' }}
                  className={classes.radioGroup}>
                  <Radio style={{ borderRadius: 6, fontSize: 14, padding: '0 15px' }} value={NODE_TYPE.DraftNode}>
              Node
                  </Radio>
                  {nodeLayers.query.nodeType === NODE_TYPE.IsoHub ? (
                     <Checkbox.Group
                        value={zonalTypes}
                        onChange={(values) => {
                           setZonalTypes(values as ('Hub' | 'Zone')[]);
                        }}
                     >
                        {iso !== DATA_PROVIDER.NYISO && (
                           <Checkbox style={{ borderRadius: 6, fontSize: 14 }} value="Hub">
                    Hub
                           </Checkbox>
                        )}
                        <Checkbox style={{ borderRadius: 6, fontSize: 14 }} value="Zone">
                  Zone
                        </Checkbox>
                     </Checkbox.Group>
                  ) : (
                     <Radio disabled={!isUserType(USER_TYPE.INTERNAL)} style={{ borderRadius: 6, fontSize: 14, padding: '0 15px' }} value={NODE_TYPE.IsoHub}>
                Zone / Hub
                     </Radio>
                  )}
               </Flex>
            </Radio.Group>

            <div className={classes.radioGroup}>
               <Radio.Group
                  size="small" style={{ width: '100%' }} buttonStyle={'solid'}
                  value={nodeLayers.layerOption} onChange={(e) => setLayerOption(e.target.value)}>
                  <Flex justify="space-around" align="center" style={{ padding: '8px 0px 4px' }}>
                     {layerOptions.map((layer) => (
                        <Radio.Button style={{ border: 'none', fontSize: 14, padding: '0 15px' }} key={layer} value={layer}>
                           {layer}
                        </Radio.Button>
                     ))}
                  </Flex>
               </Radio.Group>
            </div>

            <>
               <div className={classes.queryBase}>
                  <Radio.Group
                     value={nodeLayers.query.marketType || MARKET_TYPE.DayAhead} onChange={(e) => changeQuery({ marketType: e.target.value })} disabled={nodeLayers.loading}
                     size="small">
                     <Radio style={{ border: 'none' }} key={MARKET_TYPE.DayAhead} value={MARKET_TYPE.DayAhead}>
                DA
                     </Radio>
                     <Radio style={{ border: 'none' }} key={MARKET_TYPE.Realtime} value={MARKET_TYPE.Realtime}>
                RT
                     </Radio>
                  </Radio.Group>
                  <Select
                     variant="borderless" value={nodeLayers.query.periodType || PERIOD_TYPE.last_month} onChange={(value) => changeQuery({ periodType: value })}
                     disabled={nodeLayers.loading} size="small" style={{ width: 115, textAlign: 'center' }}>
                     {/* <Select.Option value={PERIOD_TYPE.last_month}>Last Month</Select.Option> */}
                     {/* <Select.Option value={PERIOD_TYPE.last_quarter}>Last Quarter</Select.Option> */}
                     <Select.Option value={PERIOD_TYPE.this_year}>This Year</Select.Option>
                     <Select.Option value={PERIOD_TYPE.last_year}>Last Year</Select.Option>
                     <Select.Option value={PERIOD_TYPE.last_2_year}>Last 2 Years</Select.Option>
                     <Select.Option value={PERIOD_TYPE.last_3_year}>Last 3 Years</Select.Option>
                     <Select.Option value={PERIOD_TYPE.last_4_year}>Last 4 Years</Select.Option>
                     <Select.Option value={PERIOD_TYPE.last_5_year}>Last 5 Years</Select.Option>
                  </Select>
                  <Checkbox checked={nodeLayers.query.showAll} onChange={(e) => changeQuery({ showAll: e.target.checked })} disabled={nodeLayers.loading}>
              Show All
                  </Checkbox>
               </div>

               {nodeLayers.query.component === NODE_MAP_DATA_COMPONENT.TBX && (
                  <Flex justify="space-around" align="center" style={{ paddingBottom: 4 }}>
                     <Select
                        value={nodeLayers.query.tbxType ?? TXBX_COMPONENT.Tb1} onChange={(value) => changeQuery({ tbxType: value })} variant="borderless"
                        disabled={nodeLayers.loading} size="small">
                        <Select.Option value={TXBX_COMPONENT.Tb1}>TB1</Select.Option>
                        <Select.Option value={TXBX_COMPONENT.Tb2}>TB2</Select.Option>
                        <Select.Option value={TXBX_COMPONENT.Tb3}>TB3</Select.Option>
                        <Select.Option value={TXBX_COMPONENT.Tb4}>TB4</Select.Option>
                     </Select>
                     <Select
                        value={nodeLayers.query.tbxValueType ?? TBX_VALUE_TYPE.Average} onChange={(value) => changeQuery({ tbxValueType: value })} variant="borderless"
                        disabled={nodeLayers.loading} size="small">
                        {nodeLayers.query.nodeType !== NODE_TYPE.IsoHub && <Select.Option value={TBX_VALUE_TYPE.Performance}>Performance</Select.Option>}
                        <Select.Option value={TBX_VALUE_TYPE.Average}>Average</Select.Option>
                     </Select>
                  </Flex>
               )}

               {nodeLayers.query.component === NODE_MAP_DATA_COMPONENT.BATTER_SITING && (
                  <Flex justify="space-around" align="center" style={{ paddingBottom: 4 }}>
                     <Select
                        value={nodeLayers.query.durationHour ?? 1} onChange={(value) => changeQuery({ durationHour: value })} variant="borderless"
                        disabled={nodeLayers.loading} size="small">
                        <Select.Option value={1}>1 Hour</Select.Option>
                        <Select.Option value={2}>2 Hour</Select.Option>
                        <Select.Option value={3}>3 Hour</Select.Option>
                        <Select.Option value={4}>4 Hour</Select.Option>
                     </Select>
                     <Select
                        value={nodeLayers.query.sitingValueType ?? PRICE_COMPONENT.BATTERY_PROFIT_PER_MW} onChange={(value) => changeQuery({ sitingValueType: value })} variant="borderless"
                        disabled={nodeLayers.loading} size="small" style={{ width: 120 }}>
                        <Select.Option value={PRICE_COMPONENT.BATTERY_PROFIT_PER_MW}>Profit per kW</Select.Option>
                     </Select>
                  </Flex>
               )}

               {nodeLayers.loading === false && minmax[0] !== minmax[1] && (
                  <div className={classes.legendControl}>
                     <Legend scaleValues={[{ position: 'bottom', label: 'Price ($/MWh)', minmax }]} numberFormat={(n: number) => Math.round(n)} />
                  </div>
               )}
            </>
         </div>
      </BaseSection>
   );
};

export default NodeLayersSection;
