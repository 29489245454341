/* eslint-disable max-len */
import axios, { AxiosInstance } from 'axios';
import { Feature, FeatureCollection, Point, Polygon } from 'geojson';
import { IBasisRiskCommonReqModel } from 'modules/NodeAnalysis/Container/Tabs/basis-risk/types';
import { ILmpOverviewTimeSeriesRequest, ILmpOverviewWeightedSeriesRequest, ILmpYearlySumRequest } from 'modules/NodeAnalysis/Container/Tabs/lmp/types';
import { CACHE } from 'power/api/cache';
import { API_ROUTES, ENV } from 'power/constants';
import API_ROUTES_V2 from 'power/constants/API_ROUTES_V2';
import { INodeLayerProperties } from 'power/map/layers/NodeLayer/type';
import {
   FutureHeatmap,
   FutureHeatmapRequest,
   FuturePrice,
   FuturePriceRequest,
   FutureTbx,
   FutureTbxRequest,
   FutureTbxTimeseries,
   FutureTbxTimeseriesRequest,
   FutureTimeseries,
   FutureTimeseriesRequest,
   GenerationPocketConstraintResponse,
   GenerationPocketInfoResponse,
   GenerationPocketLargeLoad,
   GenerationPocketPlant,
   GenerationPocketTransmissionLine,
   HistoricalHeatmap,
   HistoricalHeatmapRequest,
   IArbitrageInfoBoxRequest,
   IBarChartData,
   IBaseChartData,
   IBaseDataResponse,
   IBasisRiskSumValue,
   IBasisRiskTimeSeriesRequest,
   IBindingConstraintItem,
   ICapacityBranchRequest,
   ICapacityNodesRequest,
   ICapacitySubstations,
   ICapacitySubstationsRequest,
   IChartData,
   ICongestionInfoBox,
   ICongestionShadowPriceRequest,
   ICongestionTimeSeriesRequest,
   IHeadroomCapacity,
   IHeadroomCapacityFilter,
   IICAPCapacityParameters,
   IICAPCapacityParametersRequest,
   IICAPFuture,
   IICAPFutureRequest,
   IICAPMonthly,
   IICAPMonthlyDetailRequest,
   IICAPMonthlyRequest,
   IICAPSpot,
   IICAPSpotDetailRequest,
   IICAPSpotRequest,
   IICAPStrip,
   IICAPStripDetailRequest,
   IICAPStripRequest,
   IIQDetail,
   IIQListItem,
   IIQTableRequest,
   ILmpSumValue,
   ILocationLookupMapBox,
   ILocationSimple,
   INewsListItem,
   INodeInfoRequest,
   INodeInfoResponse,
   INodeMapPageQuery,
   IOptions,
   IPageDetailResponse,
   IPaginationData,
   IPPDetail,
   IStaticLayerItem,
   ITbxSumValue,
   ITransmissionLinesDetail,
   ITxBxMovingAverageTimeSeriesRequest,
   ITxBxPerformanceTimeSeriesRequest,
   IUser
} from 'power/types';
import { DATA_PROVIDER, DATA_SUM_TYPE, FUEL_TYPE, HEADROOM_CAPACITY_DIRECTION, HEADROOM_CAPACITY_SEASON, ICAP_ZONE_NYISO, IQ_STATUS, LOCATION_TYPE, PERIOD_TYPE, PP_STATUS, PRICE_COMPONENT, TXBX_COMPONENT } from 'power/types/enum';
import IArbitrageTimeseriesRequest from 'power/types/IArbitrageTimeseriesRequest';
import { BranchInfo, ICapacityBranch, ICapacityNodes } from 'power/types/ICapacityOverview';
import { IHeadroomStaticLayers } from 'power/types/IHeadroomStaticPointFeature';
import { IPowerPlantLayerProperties } from 'power/types/map';
import { utils } from 'power/utils';

const JWT_TOKEN_KEY = 'jwt';
export class ApiClients {
   // #region Base
   private static instance: ApiClients;

   private _axiosInstanceV1: AxiosInstance;

   private _axiosInstanceV2: AxiosInstance;

   private _jwtToken: string | null = null;

   setToken = (jwtToken: string) => {
      this._jwtToken = jwtToken;
      // this._axiosInstanceV1.defaults.headers.Authorization = `${jwtToken}`;
      this._axiosInstanceV2.defaults.headers.Authorization = `Bearer ${jwtToken}`;
   };

   removeToken = () => {
      this._jwtToken = null;
      // this._axiosInstanceV1.defaults.headers.Authorization = '';
      this._axiosInstanceV2.defaults.headers.Authorization = '';
   };

   private constructor(jwtToken?: string) {
      this._axiosInstanceV1 = axios.create({
         baseURL: ENV.BASE_API_URL,
         timeout: 180000,
         timeoutErrorMessage: 'Timeout error occurred, please try again later.',
         headers: { 'Content-Type': 'application/json' },
      });
      this._axiosInstanceV2 = axios.create({
         baseURL: ENV.BASE_API_V2_URL,
         timeout: 180000,
         timeoutErrorMessage: 'Timeout error occurred, please try again later.',
         headers: { 'Content-Type': 'application/json' },
      });

      if (jwtToken) localStorage.setItem(JWT_TOKEN_KEY, jwtToken);

      const token = localStorage.getItem(JWT_TOKEN_KEY);
      if (token) {
         this.setToken(token);
      }
   }

   public static getInstance(): ApiClients {
      if (!ApiClients.instance) {
         ApiClients.instance = new ApiClients();
      }
      return ApiClients.instance;
   }

   activateAccount = async (firstName: string, lastName: string, password: string, token: string): Promise<boolean> => {
      try {
         await this.post2<string, { firstName: string; lastName: string; password: string; token: string }>(API_ROUTES_V2.AUTH.ActivateAccount, { firstName, lastName, password, token });
         return true;
      } catch (error: any) {
         return false;
      }
   };

   public async me(): Promise<IUser | undefined> {
      try {
         if (!this._jwtToken) return undefined;

         const user = await this._axiosInstanceV2.get<{ data: IUser } | undefined>(API_ROUTES_V2.AUTH.Me).then((res) => res.data?.data);
         return user;
      } catch (error: any) {
         throw new Error('Me error ' + JSON.stringify(error));
      }
   }

   public async login(userName: string, password: string): Promise<IUser | undefined> {
      try {
         const jwtToken = await this._axiosInstanceV2.post<{ access_token: string } | undefined>(API_ROUTES_V2.AUTH.Login, { username: userName, password }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).then((res) => res.data?.access_token);

         if (!jwtToken) return undefined;

         localStorage.setItem(JWT_TOKEN_KEY, jwtToken);
         this.setToken(jwtToken);

         const user = this.me();
         if (!user) {
            this.removeToken();
            return undefined;
         }

         return user;
      } catch (error: any) {
         throw new Error('login error ' + JSON.stringify(error));
      }
   }

   public changePassword = async (id: number, oldPass: string, newPass: string): Promise<boolean> => {
      try {
         const res = await this.post2<string, unknown>(API_ROUTES_V2.AUTH.ChangePassword, { currentPassword: oldPass, newPassword: newPass });
         return res === null;
      } catch (error: any) {
         throw error;
      }
   };

   public forgotPassword = async (email: string): Promise<boolean> => {
      try {
         await this.post2<string, { email: string }>(API_ROUTES_V2.AUTH.ForgotPassword, { email });
         return true;
      } catch (error: any) {
         return false;
      }
   };

   resetPassword = async (apiKey: string, password: string): Promise<boolean> => {
      try {
         await this.post2<string, { token: string; password: string }>(API_ROUTES_V2.AUTH.ResetPassword, { token: apiKey, password });
         return true;
      } catch (error: any) {
         return false;
      }
   };

   public logout(): void {
      localStorage.removeItem(JWT_TOKEN_KEY);
      this._jwtToken = null;
   }

   private async get1<Response, Request>(url: string, data: Request): Promise<Response> {
      return this._axiosInstanceV1.get<Response>(url, { params: data }).then((res) => res.data);
   }

   private async get2<Response, Request>(url: string, data: Request, withModels = true): Promise<Response> {
      return this._axiosInstanceV2.get<{ data: Response }>(url, { params: data }).then((res) => (withModels ? res.data.data : (res.data as any)));
   }

   private async post1<Response, Request>(url: string, data: Request): Promise<Response> {
      return this._axiosInstanceV1.post<Response>(url + '?jsconfig=teai', data).then((res) => res.data);
   }

   private async post2<Response, Request>(url: string, data: Request): Promise<Response> {
      return this._axiosInstanceV2.post<Response>(url, data).then((res) => res.data);
   }
   // #endregion Base

   // #region ICAP (v2)
   public async icapStrip(query: IICAPStripRequest): Promise<IICAPStrip[]> {
      return this.get2<IICAPStrip[], IICAPStripRequest>(API_ROUTES_V2.ICAP.Strip, query);
   }

   public async icapStripDetail(query: IICAPStripDetailRequest): Promise<IICAPStrip[]> {
      return this.get2<IICAPStrip[], IICAPStripDetailRequest>(API_ROUTES_V2.ICAP.StripDetail, query);
   }

   public async icapSpot(query: IICAPSpotRequest): Promise<IICAPSpot[]> {
      return this.get2<IICAPSpot[], IICAPSpotRequest>(API_ROUTES_V2.ICAP.Spot, query);
   }

   public async icapSpotDetail(query: IICAPSpotDetailRequest): Promise<IICAPSpot[]> {
      return this.get2<IICAPSpot[], IICAPSpotDetailRequest>(API_ROUTES_V2.ICAP.SpotDetail, query);
   }

   public async icapMonthly(query: IICAPMonthlyRequest): Promise<IICAPMonthly[]> {
      return this.get2<IICAPMonthly[], IICAPMonthlyRequest>(API_ROUTES_V2.ICAP.Monthly, query);
   }

   public async icapMonthlyDetail(query: IICAPMonthlyDetailRequest): Promise<IICAPMonthly[]> {
      return this.get2<IICAPMonthly[], IICAPMonthlyDetailRequest>(API_ROUTES_V2.ICAP.MonthlyDetail, query);
   }

   public async icapCapacityParameters(query: IICAPCapacityParametersRequest): Promise<IICAPCapacityParameters[]> {
      return this.get2<IICAPCapacityParameters[], IICAPCapacityParametersRequest>(API_ROUTES_V2.ICAP.CapacityParameters, query);
   }

   public async icapTimeseriesStrip(query: { zone: ICAP_ZONE_NYISO }): Promise<IICAPStrip[]> {
      return this.get2<IICAPStrip[], { zone: ICAP_ZONE_NYISO }>(API_ROUTES_V2.ICAP.TimeseriesStrip, query);
   }

   public async icapTimeseriesSpot(query: { zone: ICAP_ZONE_NYISO }): Promise<IICAPSpot[]> {
      return this.get2<IICAPSpot[], { zone: ICAP_ZONE_NYISO }>(API_ROUTES_V2.ICAP.TimeseriesSpot, query);
   }

   public async icapTimeseriesMonthly(query: { zone: ICAP_ZONE_NYISO }): Promise<IICAPMonthly[]> {
      return this.get2<IICAPMonthly[], { zone: ICAP_ZONE_NYISO }>(API_ROUTES_V2.ICAP.TimeseriesMonthly, query);
   }

   public async icapFuture(query: IICAPFutureRequest): Promise<IICAPFuture[]> {
      return this.get2<IICAPFuture[], IICAPFutureRequest>(API_ROUTES_V2.ICAP.Future, query);
   }

   public async icapFutureTimeseries(query: { zone: ICAP_ZONE_NYISO }): Promise<IICAPFuture[]> {
      return this.get2<IICAPFuture[], { zone: ICAP_ZONE_NYISO }>(API_ROUTES_V2.ICAP.FutureTimeseries, query);
   }
   // #endregion ICAP

   // #region Future (v2)
   public async futurePrice(query: FuturePriceRequest): Promise<FuturePrice[]> {
      return this.get2<FuturePrice[], FuturePriceRequest>(API_ROUTES_V2.FUTURE.Price, query);
   }

   public async futureHeatmap(query: FutureHeatmapRequest): Promise<FutureHeatmap> {
      return this.get2<FutureHeatmap, FutureHeatmapRequest>(API_ROUTES_V2.FUTURE.Heatmap, query);
   }

   public async futureTimeseries(query: FutureTimeseriesRequest): Promise<FutureTimeseries[]> {
      return this.get2<FutureTimeseries[], FutureTimeseriesRequest>(API_ROUTES_V2.FUTURE.Timeseries, query);
   }

   public async futureTbx(query: FutureTbxRequest): Promise<FutureTbx[]> {
      return this.get2<FutureTbx[], FutureTbxRequest>(API_ROUTES_V2.FUTURE.Tbx, query);
   }

   public async futureTbxTimeseries(query: FutureTbxTimeseriesRequest): Promise<FutureTbxTimeseries[]> {
      return this.get2<FutureTbxTimeseries[], FutureTbxTimeseriesRequest>(API_ROUTES_V2.FUTURE.TBxTimeSeries, query);
   }
   // #endregion Future

   // #region Headroom (v1-v2)
   headroomMapData = async (filter: IHeadroomCapacityFilter): Promise<IHeadroomCapacity> => {
      // const cacheKey: any[] = [API_ROUTES.HEADROOM_CAPACITY.Map, filter.direction, filter.season, filter.iso];
      const data = await this.get2<IHeadroomCapacity, IHeadroomCapacityFilter>(API_ROUTES_V2.HEADROOM_CAPACITY.Map, filter);

      return {
      // [id, voltage, value, lng, lat, cost]
         substations: data.substations.filter((sub) => filter.voltage.min <= sub[3] && sub[3] <= filter.voltage.max && (filter.maxRefurbishmentCost === 0 || sub[4] <= filter.maxRefurbishmentCost)),
         // [id, lnglat1, lnglat2, value, voltage, cost]
         transmissionLines: data.transmissionLines.filter((tl) => filter.voltage.min <= tl[3] && tl[3] <= filter.voltage.max && (filter.maxRefurbishmentCost === 0 || tl[4] <= filter.maxRefurbishmentCost)),
      };
   };

   headroomOverviewSubstations = async (query: ICapacitySubstationsRequest): Promise<ICapacitySubstations> => this.get2<ICapacitySubstations, ICapacitySubstationsRequest>(API_ROUTES_V2.HEADROOM_CAPACITY.Substations, query);

   headroomOverviewBranch = async (query: ICapacityBranchRequest): Promise<ICapacityBranch> => this.get2<ICapacityBranch, ICapacityBranchRequest>(API_ROUTES_V2.HEADROOM_CAPACITY.Branch, query);

   headroomBranches = async (substationId: number, reload: boolean = false): Promise<BranchInfo[]> => this.get2<BranchInfo[], { substation_id: number; direction: HEADROOM_CAPACITY_DIRECTION; season: HEADROOM_CAPACITY_SEASON.SUMMER }>(API_ROUTES_V2.HEADROOM_CAPACITY.Branches, {
      substation_id: substationId,
      direction: HEADROOM_CAPACITY_DIRECTION.CHARGING,
      season: HEADROOM_CAPACITY_SEASON.SUMMER,
   });

   headroomConstraintsBySubstation = async (substationId: number, reload: boolean = false): Promise<IBindingConstraintItem[]> => this.get2<IBindingConstraintItem[], { substationId: number }>(API_ROUTES_V2.HEADROOM_CAPACITY.ConstraintsBySubstation, { substationId });

   headroomConstraintsByBranch = async (branchId: string, reload: boolean = false): Promise<IBindingConstraintItem[]> => this.get2<IBindingConstraintItem[], { branchId: string }>(API_ROUTES_V2.HEADROOM_CAPACITY.ConstraintsByBranch, { branchId });

   headroomStaticLayers = async (iso: DATA_PROVIDER): Promise<IHeadroomStaticLayers> => this.get2<IHeadroomStaticLayers, { iso: DATA_PROVIDER }>(API_ROUTES_V2.HEADROOM_CAPACITY.StaticLayers, { iso });
   // #endregion Headroom

   // #region Congestion
   headroomCongestionInfoBox = async (nodeId: number, periodType: PERIOD_TYPE): Promise<ICongestionInfoBox[]> => this.post1<ICongestionInfoBox[], { nodeId: number; periodType: PERIOD_TYPE }>(API_ROUTES.CONGESTION.InfoBox, { nodeId, periodType });

   headroomCongestionShadowPrice = async (filter: ICongestionShadowPriceRequest): Promise<string[]> => this.post1<string[], ICongestionShadowPriceRequest>(API_ROUTES.CONGESTION.ShadowPrice, filter);

   headroomCongestionTimeSeries = async (filter: ICongestionTimeSeriesRequest) => this.post1<IBaseDataResponse<IBaseChartData<IChartData>, ICongestionTimeSeriesRequest>, ICongestionTimeSeriesRequest>(API_ROUTES.CONGESTION.TimeSeries, filter);
   // #endregion Congestion

   // #region Map Layers (v1)
   mapNode = async (partialNodeMapPageQuery: Partial<INodeMapPageQuery>): Promise<FeatureCollection<Point, INodeLayerProperties>> => {
      const datas = await this.post1<number[], Partial<INodeMapPageQuery>>(API_ROUTES.MAP.Node, { ...partialNodeMapPageQuery, showAll: undefined });
      let geojson = utils.map.arrayToGeoJsonPoint<INodeLayerProperties>(datas, ['value', 'name', 'offset', 'month'], undefined);

      geojson.features = geojson.features
         .filter((x) => partialNodeMapPageQuery.showAll || x.properties.month <= 0)
         .map((feature) => {
            const { properties } = feature;
            properties.offsetIcon = [0, 32 * properties.offset];
            properties.offsetText = [1.85, 1 * properties.offset];
            return feature;
         });

      return geojson;
   };

   mapIq = (provider: DATA_PROVIDER): Promise<FeatureCollection<Point, { id: number }>> => this.post1<IStaticLayerItem[], { type: string; provider: DATA_PROVIDER }>(API_ROUTES.IQ.Map, { type: 'InterconnectionQueue', provider }).then((data) => {
      const result: FeatureCollection<Point, { id: number }> = {
         type: 'FeatureCollection',
         features: [],
      };
      const activeData = data.filter((item) => item.status === IQ_STATUS.Active);
      activeData.forEach((item) => {
         const feature: Feature<Point, { id: number }> = {
            type: 'Feature',
            geometry: {
               type: 'Point',
               coordinates: [item.coordinates[0][1], item.coordinates[0][0]],
            },
            properties: {
               id: item.id,
               // name: item.name,
               // voltage: item.voltage,
               // capacityMW: item.capacityMW,
               // status: item.status,
            },
         };
         result.features.push(feature);
      });
      return result;
   });

   mapPowerPlant = (provider: DATA_PROVIDER): Promise<FeatureCollection<Point, IPowerPlantLayerProperties>> => this.post1<IStaticLayerItem[], { type: string; provider: DATA_PROVIDER }>(API_ROUTES.MAP.PP, { type: 'PowerPlant', provider }).then((data) => {
      const result: FeatureCollection<Point, IPowerPlantLayerProperties> = {
         type: 'FeatureCollection',
         features: [],
      };
      const activeData = data.filter((item) => item.status === PP_STATUS.Operating);
      activeData.forEach((item) => {
         const feature: Feature<Point, IPowerPlantLayerProperties> = {
            type: 'Feature',
            geometry: {
               type: 'Point',
               coordinates: [item.coordinates[0][1], item.coordinates[0][0]],
            },
            properties: {
               id: item.id,
               b_f_type: (item.filterParameters.find((x) => x.type === 1)?.optionValue as FUEL_TYPE) ?? FUEL_TYPE.Undefined,
               // name: item.name,
               // voltage: item.voltage,
               // capacityMW: item.capacityMW,
               // status: item.status,
            },
         };
         result.features.push(feature);
      });
      return result;
   });

   mapIqDetail = (id: number) => this.post1<IIQDetail, { id: number }>(API_ROUTES.IQ.Detail, { id });

   mapIqTable = (filter: IIQTableRequest) => this.post1<IPaginationData<IIQListItem>, IIQTableRequest>(API_ROUTES.IQ.Table, filter);

   mapPowerPlantDetail = (id: number) => this.post1<IPPDetail, { id: number }>(API_ROUTES.PP.Detail, { id });

   mapTransmissionLineDetail = (id: number) => this.post1<ITransmissionLinesDetail, { id: number }>(API_ROUTES.TL.Detail, { id });
   // #endregion Map Layers

   // #region Node (v1)
   nodesOverview = async (nodeId: number): Promise<ICapacityNodes> => this.post1<ICapacityNodes, ICapacityNodesRequest>(API_ROUTES.NODE.Info, { nodeId, direction: HEADROOM_CAPACITY_DIRECTION.CHARGING, season: HEADROOM_CAPACITY_SEASON.SUMMER });

   nodeList = (query: { iso: DATA_PROVIDER; limit?: number }) => this.post1<ILocationSimple[], { provider: DATA_PROVIDER; type: number; limit?: number }>(API_ROUTES.NODE.List, { provider: query.iso, type: 110, limit: query.limit ?? 20000 });

   nodeInfo = (query: Partial<INodeInfoRequest>) => this.post1<{ result: INodeInfoResponse }, Partial<INodeInfoRequest>>(API_ROUTES.NODE.PageDetail, query);
   // #endregion Node

   // #region Locations (v1)
   locationMapboxSearch = (term: string, type: LOCATION_TYPE, bboxes: (number | string)[], provider?: DATA_PROVIDER, limit?: number) => this.post1<ILocationLookupMapBox[], { term: string; type: LOCATION_TYPE; bboxes: (number | string)[]; provider?: DATA_PROVIDER; limit?: number }>(API_ROUTES.LOCATION.MapboxSearch, {
      term,
      type,
      bboxes,
      provider,
      limit,
   });

   locationSearch = async (term: string, type: LOCATION_TYPE, bboxes: (number | string)[], provider?: DATA_PROVIDER, limit?: number) => {
      const substations = await this.searchSubstations(provider ?? DATA_PROVIDER.NYISO, term, limit);
      const mapbox = await this.locationMapboxSearch(term, type, bboxes, provider, limit);
      return [...substations, ...mapbox];
   };

   locationSubstations = (provider?: DATA_PROVIDER, hubId?: number, substationId?: number, substationSource?: number): Promise<IOptions> => this.get2<IOptions, undefined>(API_ROUTES_V2.HEADROOM_CAPACITY.SubstationsList, undefined);

   searchSubstations = (provider: DATA_PROVIDER, term: string, limit = 100): Promise<ILocationLookupMapBox[]> => this.get2<ILocationLookupMapBox[], { provider: DATA_PROVIDER; term: string; limit: number }>(API_ROUTES_V2.HEADROOM_CAPACITY.SubstationsSearch, { provider, term, limit });
   // #endregion Locations

   // #region LMP (v1)
   lmpSumValues = async (nodeId: number, periodType: PERIOD_TYPE, reload: boolean = false): Promise<ILmpSumValue[]> => this.post1<ILmpSumValue[], { nodeId: number; periodType: PERIOD_TYPE }>(API_ROUTES.LMP.SumValues, { nodeId, periodType });

   lmpTimeSeries = (data: ILmpOverviewTimeSeriesRequest, reload: boolean = false) => this.post1<IBaseDataResponse<IBaseChartData<IChartData>, ILmpOverviewTimeSeriesRequest>, ILmpOverviewTimeSeriesRequest>(API_ROUTES.LMP.TimeSeries, data);

   lmpYearlySum = (data: ILmpYearlySumRequest, reload: boolean = false): Promise<IBaseDataResponse<IBaseChartData<IChartData>>> => this.post1<IBaseDataResponse<IBaseChartData<IChartData>>, ILmpYearlySumRequest>(API_ROUTES.LMP.YearlySum, data);

   lmpHeatmap = async (query: HistoricalHeatmapRequest, reload: boolean = false): Promise<HistoricalHeatmap[]> => this.post1<IBaseDataResponse<IBaseChartData<IChartData>, HistoricalHeatmapRequest>, HistoricalHeatmapRequest>(API_ROUTES.LMP.Heatmap, query).then((x) => (x.result?.data ?? []) as any as HistoricalHeatmap[]);

   lmpWeightedSeries = (data: ILmpOverviewWeightedSeriesRequest) => this.post1<IBaseDataResponse<IBaseChartData<IChartData>>, ILmpOverviewWeightedSeriesRequest>(API_ROUTES.LMP.WeightedSeries, data);
   // #endregion LMP

   // #region TBx (v1)
   tbxSumValues = async (nodeId: number, periodType: PERIOD_TYPE, reload: boolean = false): Promise<ITbxSumValue[]> => this.post1<ITbxSumValue[], { nodeId: number; periodType: PERIOD_TYPE }>(API_ROUTES.TBx.SumValues, { nodeId, periodType });

   tbxPerformanceTimeSeries = async (filter: Partial<ITxBxPerformanceTimeSeriesRequest>, reload: boolean = false) => this.post1<IBaseDataResponse<IBaseChartData<IChartData>, ITxBxPerformanceTimeSeriesRequest>, Partial<ITxBxPerformanceTimeSeriesRequest>>(API_ROUTES.TBx.PerformanceTimeSeries, filter);

   tbxMovingAverageTimeSeries = async (filter: Partial<ITxBxMovingAverageTimeSeriesRequest>, reload: boolean = false) => this.post1<IBaseDataResponse<IBaseChartData<IChartData>, ITxBxMovingAverageTimeSeriesRequest>, Partial<ITxBxMovingAverageTimeSeriesRequest>>(API_ROUTES.TBx.MovingAverageTimeSeries, filter);
   // #endregion TBx

   // #region Basis Risk (v1)
   basisRiskSumValues = async (nodeId: number, periodType: PERIOD_TYPE, reload: boolean = false): Promise<IBasisRiskSumValue[]> => this.post1<IBasisRiskSumValue[], { nodeId: number; periodType: PERIOD_TYPE }>(API_ROUTES.BASIS_RISK.SumValues, { nodeId, periodType });

   basisRiskTimeSeries = async (filter: IBasisRiskTimeSeriesRequest): Promise<IBaseDataResponse<IBaseChartData<IBarChartData>>> => this.post1<IBaseDataResponse<IBaseChartData<IBarChartData>>, IBasisRiskTimeSeriesRequest>(API_ROUTES.BASIS_RISK.TimeSeries, filter);

   basisRiskYearlySum = (data: IBasisRiskCommonReqModel) => this.post1<IBaseDataResponse<IBaseChartData<IChartData>>, IBasisRiskCommonReqModel>(API_ROUTES.BASIS_RISK.YearlySum, data);
   // #endregion Basis Risk

   // #region Arbitrage (v1)
   arbitrageInfoBox = async (nodeId: number, hour: TXBX_COMPONENT): Promise<ITbxSumValue[]> => this.post1<ITbxSumValue[], IArbitrageInfoBoxRequest>(API_ROUTES.Arbitrage.InfoBox, {
      nodeId,
      hour,
      periodType: PERIOD_TYPE.last_5_year,
      component: PRICE_COMPONENT.BATTERY_REVENUE_KW_YEAR,
   });

   arbitrageTimeSeries = async (nodeId: number, hour: TXBX_COMPONENT, dayaheadMarket: number, realtimeMarket: number, reload: boolean = false) => this.post1<IBaseDataResponse<IBaseChartData<IChartData>, IArbitrageTimeseriesRequest>, IArbitrageTimeseriesRequest>(API_ROUTES.Arbitrage.TimeSeries, {
      nodeId,
      hours: [hour],
      periodType: PERIOD_TYPE.last_5_year,
      sumType: DATA_SUM_TYPE.Monthly,
      components: [PRICE_COMPONENT.BATTERY_REVENUE_KW_YEAR, PRICE_COMPONENT.BATTERY_CHARGING_MWH, PRICE_COMPONENT.BATTERY_DISCHARGING_MWH],
      dayaheadMarket,
      realtimeMarket,
   });
   // #endregion Arbitrage

   // #region Network (v1)
   ApiRequest = async <R, P>(ROUTE: string, payload: P, reload: boolean): Promise<R> => {
      const cacheKey: any[] = [ROUTE, payload];
      if (reload) CACHE.delete(cacheKey);
      else {
         const cachedData = CACHE.get<R | undefined>(cacheKey);
         if (cachedData) return Promise.resolve(cachedData);
      }

      return this.post1<R, P>(ROUTE, payload).then((data) => {
         CACHE.set(cacheKey, data);
         return data;
      });
   };

   ApiRequestChart = async <P>(ROUTE: string, payload: P, reload = false): Promise<IBaseDataResponse<IBaseChartData<IChartData>, P>> => this.ApiRequest<IBaseDataResponse<IBaseChartData<IChartData>, P>, P>(ROUTE, payload, reload);

   ApiRequestPage = async <F, R>(ROUTE: string, partialFilter: Partial<F>, reload = false): Promise<IPageDetailResponse<F, R>> => this.ApiRequest<IPageDetailResponse<F, R>, Partial<F>>(ROUTE, partialFilter, reload);
   // #endregion Network

   // #region News (v2)
   newsList = async () => this.get2<INewsListItem[], undefined>(API_ROUTES_V2.NEWS.List, undefined);
   // #endregion News

   // #region Generation Pockets (v2)
   generationPocketPolygons = async (): Promise<FeatureCollection<Polygon, { id: number; name: string }>> => {
      const data = await this.get2<{ id: number; name: string; geometry: { type: 'Polygon'; coordinates: number[][][] } }[], undefined>(API_ROUTES_V2.GENERATION_POCKET.Map, undefined);

      const geojson: FeatureCollection<Polygon, { id: number; name: string }> = {
         type: 'FeatureCollection',
         features: [],
      };

      data.forEach((item) => {
         const feature: Feature<Polygon, { id: number; name: string }> = {
            type: 'Feature',
            geometry: {
               type: 'Polygon',
               coordinates: item.geometry.coordinates,
            },
            properties: {
               id: item.id,
               name: item.name,
            },
         };
         geojson.features.push(feature);
      });

      return geojson;
   };

   // await utils.sleep(1000);
   // return constaintsResponse;
   generationPocketConstraintList = async (): Promise<GenerationPocketConstraintResponse> => this.get2<GenerationPocketConstraintResponse, undefined>(API_ROUTES_V2.GENERATION_POCKET.ConstraintList, undefined);

   generationPocketInfoList = (): Promise<GenerationPocketInfoResponse> => this.get2<GenerationPocketInfoResponse, undefined>(API_ROUTES_V2.GENERATION_POCKET.Info, undefined);

   generationPocketTransmissionLineList = (): Promise<GenerationPocketTransmissionLine[]> => this.get2<GenerationPocketTransmissionLine[], undefined>(API_ROUTES_V2.GENERATION_POCKET.TransmissionLineList, undefined);

   generationPocketPlantList = (): Promise<GenerationPocketPlant[]> => this.get2<GenerationPocketPlant[], undefined>(API_ROUTES_V2.GENERATION_POCKET.PlantList, undefined);

   generationPocketLargeLoadList = (): Promise<GenerationPocketLargeLoad[]> => this.get2<GenerationPocketLargeLoad[], undefined>(API_ROUTES_V2.GENERATION_POCKET.LargeLoad, undefined);

   // #endregion Generation Pockets
}
