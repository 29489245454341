import { ApiClients } from 'ApiClients';
import { IIQListItem, IIQTableRequest, IPaginationData } from 'power/types';
import { DATA_PROVIDER, IQ_PAGE_ORDER } from 'power/types/enum';
import { useIso } from 'providers/useIso';
import { useCallback, useState } from 'react';

export const initialFilter: IIQTableRequest = {
   countyIds: [],
   fuelTypes: [],
   pageSize: 10,
   minCapacity: 0,
   statuses: [],
   providers: [DATA_PROVIDER.NYISO],
   stateId: 0,
   maxCapacity: 0,
   startYear: 0,
   endYear: 0,
   pageNumber: 1,
   searchText: '',
   order: IQ_PAGE_ORDER.Undefined,
};

const useIQTable = () => {
   const { iso } = useIso();
   const [loading, setLoading] = useState(true);
   const [data, setData] = useState<IPaginationData<IIQListItem> | undefined>(undefined);

   const fetch = useCallback(async (filter: IIQTableRequest) => {
      setLoading(true);
      return ApiClients.getInstance().mapIqTable({...filter, providers: [iso]})
         .then((data) => {
            setData(data);
         })
         .catch((error) => {
            console.error(error);
            setData(undefined);
         })
         .finally(() => {
            setLoading(false);
         });
   }, [iso]);

   return {
      loading,
      data,
      fetch,
   };
};

export default useIQTable;
