import { GenerationPocketConstraintResponse, GenerationPocketInfoResponse } from 'power/types';
import { useMemo, useState } from 'react';
import { useConstraints } from './useConstraints';
import { useInfos } from './useInfos';
import { useLargeLoads } from './useLargeLoad';
import { usePlants } from './usePlants';
import { usePockets } from './usePockets';
import { useTransmissionLines } from './useTransmissionLines';

export const useData = () => {
   const { loadingPockets, pockets } = usePockets();
   const { loadingConstraintList, constraintList } = useConstraints();
   const { loadingInfoList, infoList } = useInfos();
   const { loadingTransmissionLines, transmissionLinesList } = useTransmissionLines();
   const { loadingPlants, plantsList } = usePlants();
   const {loadingLargeLoads, largeLoadsList} = useLargeLoads();

   const [selectedPocket, setSelectedPocket] = useState<number | null>(null);

   const selectedConstraints: GenerationPocketConstraintResponse = useMemo(() => {
      if (!selectedPocket) return [];
      return constraintList.map(d => ({target_year: d.target_year, constraints: d.constraints.filter((x) => x.generation_pocket_id === selectedPocket)}));
   }, [selectedPocket, constraintList]);

   const selectedInfos: GenerationPocketInfoResponse = useMemo(() => {
      if (!selectedPocket) return [];
      return infoList.map(x => ({target_year: x.target_year, info: x.info.filter((y) => y.generation_pocket_id === selectedPocket)}));
   }, [selectedPocket, infoList]);

   return {
      loading: loadingPockets || loadingConstraintList || loadingInfoList || loadingTransmissionLines || loadingPlants || loadingLargeLoads, 
      pockets,
      selectedPocket,
      setSelectedPocket,

      constraintList,
      selectedConstraints,

      infoList,
      selectedInfos,

      transmissionLinesList,
      plantsList,
      largeLoadsList
   };
};
