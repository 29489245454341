import { DATA_PROVIDER } from 'power/types/enum';
import { useAuth } from 'providers/AuthProvider';
import { useIso } from 'providers/useIso';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const IndexPage = () => {
   const { iso } = useIso();
   const { isos } = useAuth();
   const navigate = useNavigate();

   useEffect(() => {
      if (iso === DATA_PROVIDER.Undefined) {
         const isoString = localStorage.getItem('iso');
         if (isoString && isos.includes(Number(isoString))) {
            navigate(`${DATA_PROVIDER[isoString as any].toLowerCase()}`);
         } else {
            if (isos.length > 0) {
               const defaultIso = isos.includes(DATA_PROVIDER.NYISO) ? DATA_PROVIDER.NYISO : isos[0];
               localStorage.setItem('iso', defaultIso.toString());
               navigate(`${DATA_PROVIDER[defaultIso].toLowerCase()}`);
            }
         }
      } else {
         navigate(`${DATA_PROVIDER[iso].toLowerCase()}`);
      }
   }, [iso, isos, navigate]);

   return null;
};
