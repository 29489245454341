import { Line, LineConfig } from '@ant-design/plots';
import { Radio } from 'antd';
import { ApiClients } from 'ApiClients';
import CardPanel from 'components/ui/CardPanel';
import { useContent } from 'content';
import dayjs from 'dayjs';
import { IBaseChartData, IChartData } from 'power/types';
import { CONGESTION_COMPONENT, MARKET_TYPE, PRICE_COMPONENT, SHADOW_PRICE_SCORE_TYPE, TIME_GRANULARITY } from 'power/types/enum';
import { useLmpTimeseries } from 'power/widgets/LmpTimeseries/useLmpTimeseries';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { parseDateByTimeGranularity } from 'utils/data';

const COLORS = {
   GREEN_0: '#009E73',
   GREEN: '#6dc3a0',
   GREEN_DARK: '#309E33',

   RED: '#FE6100',
   RED_DARK: '#DC267F',
   PURPLE: '#AA4499',

   BLUE: '#648FFF',
   BLUE_DARK: '#0072B2',
   BLUE_LIGHT: '#56B4E9',

   YELLOW: '#F0E442',

   ORANGE: '#FFB000',
   BLACK: '#040000',

   TRANSPARENT: '#ffffff00',
};

type Props = {
  nodeId: number;
  marketType?: MARKET_TYPE;
};

const TopFiveCongestionConstraints: FC<Props> = ({ nodeId, marketType = MARKET_TYPE.DayAhead }) => {
   const [loadingShadowPrice, setLoadingShadowPrice] = useState<boolean>(true);
   const [congestionConstraints, setCongestionConstraints] = useState<string[]>();
   const [data, setData] = useState<IBaseChartData<IChartData>[]>([]);

   const [marketTypeLocal, setMarketTypeLocal] = useState(marketType);

   const { loadingLmpTimeseries, chartData: chartDataLmp, fetchLmpTimeseries } = useLmpTimeseries();
   const { loadingLmpTimeseries: loadingMccTimeseries, chartData: chartDataMcc, fetchLmpTimeseries: fetchMccTimeseries } = useLmpTimeseries();

   const { t } = useContent();

   const fetchDataShadowPrice = useCallback(
      (reload: boolean = false) => {
         setCongestionConstraints(undefined);
         setData([]);
         setLoadingShadowPrice(true);
         ApiClients.getInstance().headroomCongestionShadowPrice({ nodeId, marketType: marketTypeLocal, scoreType: SHADOW_PRICE_SCORE_TYPE.DAILY_AVG_STD, startDate: '2015-01-01', endDate: dayjs().format('YYYY-MM-DD') })
            .then((res) => {
               setCongestionConstraints(res);
            })
            .finally(() => {
               // setLoadingShadowPrice(false);
            });
      },
      [nodeId, marketTypeLocal],
   );

   const fetchLmp = useCallback((reload: boolean = false) => {
      fetchLmpTimeseries({
         component: PRICE_COMPONENT.LMP,
         dayAheadMarket: marketTypeLocal === MARKET_TYPE.DayAhead,
         realtimeMarket: marketTypeLocal === MARKET_TYPE.Realtime,
         nodeId,
         getDiff: true,
      }, reload);
   },
   [fetchLmpTimeseries, marketTypeLocal, nodeId],
   );

   const fetchMcc = useCallback((reload: boolean = false) => {
      fetchMccTimeseries({
         component: PRICE_COMPONENT.MCC,
         dayAheadMarket: marketTypeLocal === MARKET_TYPE.DayAhead,
         realtimeMarket: marketTypeLocal === MARKET_TYPE.Realtime,
         nodeId,
         getDiff: true,
      }, reload);
   },
   [fetchMccTimeseries, marketTypeLocal, nodeId],
   );

   useEffect(() => {
      fetchDataShadowPrice();
   }, [fetchDataShadowPrice]);

   useEffect(() => {
      fetchLmp();
   }, [fetchLmp]);

   useEffect(() => {
      fetchMcc();
   }, [fetchMcc]);

   useEffect(() => {
      if (!congestionConstraints) return;
      if (congestionConstraints.length === 0) {
         setData([]);
         // setLoading(false);
         return;
      }

      const promises: Promise<IBaseChartData<IChartData>>[] = [];
      for (let i = 0; i < congestionConstraints.length; i++) {
         const p = ApiClients.getInstance().headroomCongestionTimeSeries({
            marketType: marketTypeLocal,
            component: CONGESTION_COMPONENT.Avg,
            congestionId: congestionConstraints[i],
            granularity: TIME_GRANULARITY.monthly,
            startDate: '2015-01-01',
            endDate: dayjs().format('YYYY-MM-DD'),
         })
            .then((data) => data.result);
         promises.push(p as any);
      }

      Promise.all(promises)
         .then((data) => {
            setData(data);
         })
         .catch((error) => {
            console.error(error);
            setData([]);
         })
         .finally(() => {
            setLoadingShadowPrice(false);
         });
   }, [congestionConstraints, marketTypeLocal, nodeId]);

   const constraintsChartDataDA = useMemo(() => {
      const constraintsData: { cname: string; x: string; cy: number }[] = [];
      data.forEach((constraint) => {
         constraint.data.forEach((vale) => {
            constraintsData.push({ cname: `${marketTypeLocal === MARKET_TYPE.DayAhead ? 'DA' : 'RT'} - ${constraint.labels[0]}`, x: vale.x, cy: vale.y });
         });
      });
      return constraintsData;
   }, [data, marketTypeLocal]);

   const lineConfigShadowPrice: LineConfig = useMemo(
      () => ({
      // autoFit: true,
         data: [...constraintsChartDataDA],
         xField: 'x',
         yField: 'cy',
         seriesField: 'cname',
         COLORS,
         /* xAxis: false, */
         xAxis: {
            label: {
               formatter: (x) => parseDateByTimeGranularity(x, TIME_GRANULARITY.monthly),
            },
         },
         yAxis: {
            title: {
               text: 'Constraint Cost ($/MWh)',
            },
         },
         legend: {
            position: 'top',
            padding: [0, 0, 0, 0],
            flipPage: false,
            marker: (name: string) => (name.startsWith('DA') ? {} : { symbol: 'hyphen', style: { lineDash: [4, 4] } }),
         },
         lineStyle: (datum: any) => (datum.cname.startsWith('DA') ? { lineWidth: 2 } : { lineWidth: 2, lineDash: [6, 6] }),
         stepType: ((datum: any) => (datum.cname.startsWith('DA') ? 'vh' : undefined)) as any,
         tooltip: {
            offset: 100,
            showCrosshairs: true,
            title: (title) => parseDateByTimeGranularity(title, TIME_GRANULARITY.monthly as any),
         },
         style: { height: 400, marginBottom: 36 },
      }),
      [constraintsChartDataDA],
   );

   const lineConfigLmpMcc: LineConfig = useMemo(
      () => ({
      // autoFit: true,
         data: [...(chartDataLmp?.data ?? []), ...(chartDataMcc?.data ?? [])].filter((d) => d.group !== 'DART'),
         xField: 'x',
         yField: 'y',
         seriesField: 'group',
         COLORS,
         /* xAxis: false, */
         xAxis: {
            label: {
               formatter: (x) => parseDateByTimeGranularity(x, TIME_GRANULARITY.monthly),
            },
         },
         legend: {
            position: 'bottom',
            padding: [16, 0, 0, 0],
            flipPage: false,
         },
         tooltip: {
            offset: 100,
            showCrosshairs: true,
            title: (title) => parseDateByTimeGranularity(title, TIME_GRANULARITY.monthly as any),
         },
         style: { height: 200, marginBottom: 24 },
      }),
      [chartDataLmp?.data, chartDataMcc?.data],
   );

   return (
      <CardPanel
         title="Top 5 Congestion Constraint Monthly Correlation with LMP and MCC" status={loadingShadowPrice || loadingLmpTimeseries || loadingMccTimeseries ? 'loading' : 'success'} defaultViewMode="chart"
         fullScreen refreshClick={() => {
            fetchDataShadowPrice(true);
            fetchLmp(true);
            fetchMcc(true);
         }}>
         {() => (
            <>
               <div className="card-panel-filter-bar">
                  <Radio.Group value={marketTypeLocal} onChange={(e) => setMarketTypeLocal(e.target.value)}>
                     <Radio value={MARKET_TYPE.DayAhead}>{t('base.da')}</Radio>
                     <Radio value={MARKET_TYPE.Realtime}>{t('base.rt')}</Radio>
                  </Radio.Group>
               </div>
               <Line {...lineConfigShadowPrice} />
               <Line {...lineConfigLmpMcc} />
            </>
         )}
      </CardPanel>
   );
};

export default memo(TopFiveCongestionConstraints);
