import { Flex, Spin } from 'antd';
import PowerMap from 'power/map';
import { useEffect, useState } from 'react';
import GenerationPocketControlSection from './control/GenerationPocketControlSection/GenerationPocketControlSection';
import { useData } from './hooks/useData';
import classes from './index.module.scss';
import ConstraintsLayer from './layers/ConstraintsLayer';
import InfosLayer from './layers/InfosLayer';
import LargeLoadLayer from './layers/LargeLoadLayer';
import PlantsLayer from './layers/PlantsLayer';
import PocketsLayer from './layers/PocketsLayer';
import TransmissionLinesLayer from './layers/TransmissionLinesLayer';
import { GenerationPocketsSider } from './Sider';
import { GPLayers, INITIAL_GP_LAYERS } from './type';

const MAP_ID = 'generation-pockets-v2';
const SIDER_WIDTH = 550;

export const GenerationPockets = () => {
   const [showAllPockets, setShowAllPockets] = useState(false);
   /* const [show2030, setShow2030] = useState(true);
   const [show2035, setShow2035] = useState(true); */
   const { loading, pockets, selectedPocket, setSelectedPocket, selectedConstraints, constraintList, selectedInfos, infoList, transmissionLinesList, plantsList, largeLoadsList } = useData();

   const [gpLayer, setGpLayer] = useState<GPLayers>(INITIAL_GP_LAYERS);

   useEffect(() => {
      if (pockets) {
         setGpLayer((prev) => prev && { ...prev, loading: false });
      }
   }, [loading, pockets]);

   return (
      <div className={classes.fullContainer}>
         <div className={classes.container}>
            <Flex vertical={false} style={{ height: '100%' }}>
               <div style={{ width: SIDER_WIDTH, height: '100%', borderRight: '2px solid #c9c1c1' }}>
                  {pockets && (
                     <GenerationPocketsSider
                        geojson={pockets}
                        selectedPocket={selectedPocket}
                        setSelectedPocket={setSelectedPocket}
                        constraints={selectedConstraints.length ? selectedConstraints : constraintList}
                        infos={selectedInfos.length ? selectedInfos : infoList}
                        gpLayer={gpLayer}
                        setGpLayer={setGpLayer}
                        showAllPockets={showAllPockets}
                        setShowAllPockets={setShowAllPockets}
                     />
                  )}
               </div>
               <div style={{ width: `calc(100% - ${SIDER_WIDTH}px)`, height: '100%', position: 'relative' }}>
                  <PowerMap id={MAP_ID} customSiderRightControls={[<GenerationPocketControlSection mapId={MAP_ID} gpLayer={gpLayer} setGpLayer={setGpLayer} />]}>
                     {pockets && !gpLayer.loading && (
                        <>
                           <PocketsLayer
                              mapId={MAP_ID} geojson={pockets} selectedPocket={selectedPocket}
                              setSelectedPocket={setSelectedPocket} show2030={gpLayer.year2030} show2035={gpLayer.year2035}
                              showAllPockets={showAllPockets} />
                           {gpLayer.visible.tlUpgrade && (
                              <TransmissionLinesLayer
                                 mapId={MAP_ID} transmissionLines={transmissionLinesList} selectedPocket={selectedPocket}
                                 show2030={gpLayer.year2030} show2035={gpLayer.year2035} showAllPockets={showAllPockets} />
                           )}
                           {gpLayer.visible.constraint && (
                              <ConstraintsLayer
                                 mapId={MAP_ID} constraints={constraintList} selectedPocket={selectedPocket}
                                 show2030={gpLayer.year2030} show2035={gpLayer.year2035} showAllPockets={showAllPockets} />
                           )}
                           {gpLayer.visible.largeLoad && (
                              <LargeLoadLayer
                                 mapId={MAP_ID} largeLoads={largeLoadsList} selectedPocket={selectedPocket}
                                 show2030={gpLayer.year2030} show2035={gpLayer.year2035} showAllPockets={showAllPockets} />
                           )}
                           {gpLayer.visible.plant && (
                              <PlantsLayer
                                 mapId={MAP_ID} plants={plantsList} selectedPocket={selectedPocket}
                                 show2030={gpLayer.year2030} show2035={gpLayer.year2035} showAllPockets={showAllPockets}
                                 typeFilter={gpLayer.filter.plant}
                              />
                           )}
                           <InfosLayer
                              mapId={MAP_ID} infos={infoList} selectedPocket={selectedPocket}
                              show2030={gpLayer.year2030} show2035={gpLayer.year2035} showAllPockets={showAllPockets} />
                        </>
                     )}
                  </PowerMap>
               </div>
            </Flex>
            {loading && <Spin className={classes.loading} />}
         </div>
      </div>
   );
};
