import { GenerationPocketInfoResponse } from 'power/types';
import React from 'react';
import { useMap } from 'react-map-gl';

// const LAYER_ID = 'GENERATION_POCKETS_CONSTRAINTS';

type Props = {
  mapId: string;
  infos: GenerationPocketInfoResponse;
  selectedPocket: number | null;
  show2035: boolean;
  show2030: boolean;
  showAllPockets: boolean;
};

const InfosLayer: React.FC<Props> = ({ mapId, infos, show2030, show2035, showAllPockets }) => {
   const { [mapId]: map } = useMap();

   /* useEffect(() => {
      if (!map) return;

      map.on('click', (e) => {
         const features = map.queryRenderedFeatures(e.point, { layers: [LAYER_ID] });
         if (features.length) {
            const id = features[0].properties?.id;
            setSelectedPocket(id);
         }
      });
   }, [map, setSelectedPocket]);

   useEffect(() => {
      if (!map) return;

      // set cursor to pointer when hovering over pocket
      map.on('mousemove', (e) => {
         const features = map.queryRenderedFeatures(e.point, { layers: [LAYER_ID] });
         map.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });
   }, [map]); */

   /* useEffect(() => {
      if (!map) return;

      if (selectedPocket) {
         const featue = geojson.features.find((f) => f.properties?.id === selectedPocket);
         if (!featue) return;

         // calculate bbox from coordinates
         const bbox = featue.geometry.coordinates[0].reduce((acc, coord) => [Math.min(acc[0], coord[0]), Math.min(acc[1], coord[1]), Math.max(acc[2], coord[0]), Math.max(acc[3], coord[1])], [Infinity, Infinity, -Infinity, -Infinity]);

         map.fitBounds(bbox as [number, number, number, number], {
            padding: 10,
         });
      }
   }, [map, selectedPocket, geojson]); */

   if (!map) return null;

   return null;

   /* return (
      <>
         <Source id={LAYER_ID} type="geojson" data={geojson}>
            <Layer
               id={LAYER_ID}
               type="fill"
               paint={{ 'fill-opacity': 0 }}
            />
            <Layer
               id={`${LAYER_ID}-border`} type="line" layout={ICAP_ZONE_BORDER_STYLE.LAYOUT}
               paint={{ 'line-color': '#948787', 'line-width': 2, 'line-dasharray': [2, 2] }} />

            {selectedPocket && (
               <Layer
                  id={`${LAYER_ID}-selected-border`} type="line" layout={ICAP_ZONE_BORDER_STYLE.LAYOUT}
                  paint={{ 'line-color': '#FFC53D', 'line-width': 3 }} filter={['==', ['get', 'id'], selectedPocket]} />
            )}
         </Source>
      </>
   ); */
};

export default InfosLayer;
