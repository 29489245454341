import { Flex, Radio, Space } from 'antd';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { FeatureCollection, Polygon } from 'geojson';
import { GenerationPocketConstraintResponse, GenerationPocketInfoResponse } from 'power/types';
import { FC, useMemo } from 'react';
import { GPLayers } from '../type';
import { Constraint2030, Constraint2035, getConstraints2030, getConstraints2035, getInfos2030, getInfos2035, Info2030, Info2035 } from './helper';
import classes from './index.module.scss';
import TableConstraint from './TableConstraint';
import TableInfo from './TableInfo';

const CONSTRAINTS_2030_HEADERS = ['2030 Contract Constraints', 'Number of Limiting Hours'];
const CONSTRAINTS_2030_KEYS = ['2023 Outlook Contract', '2021 Outlook Contract', '2021 Outlook Policy S1'];

const CONSTRAINTS_2035_HEADERS = ['2035 Policy Constraints', 'Number of Limiting Hours'];
const CONSTRAINTS_2035_KEYS = ['2023 Lower Demand', '2023 Higher Demand'];

const INFO_2030_HEADERS = ['Type', 'Capacity (MV)', 'Energy Deliverability (%)'];
const INFO_2030_KEYS = ['2023 Outlook Contract', '2021 Outlook Contract'];

const INFO_2035_HEADERS = ['Type', 'Capacity (MW)', 'Energy Deliverability (%)'];
const INFO_2035_KEYS = ['Contract Case', 'Lower Demand', 'Higher Demand'];

type Props = {
  geojson: FeatureCollection<Polygon, { id: number; name: string }>;
  selectedPocket: number | null;
  setSelectedPocket: (id: number | null) => void;
  constraints: GenerationPocketConstraintResponse;
  infos: GenerationPocketInfoResponse;
  gpLayer: GPLayers;
  setGpLayer: React.Dispatch<React.SetStateAction<GPLayers>>;
  showAllPockets: boolean;
  setShowAllPockets: (showAllPockets: boolean) => void;
};

export const GenerationPocketsSider: FC<Props> = ({ geojson, selectedPocket, setSelectedPocket, constraints, infos, gpLayer, setGpLayer, showAllPockets, setShowAllPockets }) => {
   const pockets = useMemo(() => {
      if (!geojson) return [];

      return geojson.features.map((feature) => ({
         id: feature.properties?.id,
         name: feature.properties?.name,
         title:
        feature.properties?.name &&
        (feature.properties.name.startsWith('W') ? 'Western New York' : feature.properties.name.startsWith('X') ? 'North Country' : feature.properties.name.startsWith('Y') ? 'Capital Region' : feature.properties.name.startsWith('Z') ? 'Southern Tier' : feature.properties.name),
      }));
   }, [geojson]);

   const constraints2030: Constraint2030[] = useMemo(() => getConstraints2030(constraints.find((x) => x.target_year === 2030)?.constraints ?? []), [constraints]);

   const constraints2035: Constraint2035[] = useMemo(() => getConstraints2035(constraints.find((x) => x.target_year === 2035)?.constraints ?? []), [constraints]);

   const constraint2030Rows = useMemo(() => {
      const allPoliciesNull = constraints2030.every((x) => x.numberOfLimitingHours_policyCaseS1_study2021 === null);

      const rows: { id: string; keys: string[]; allValues: (number | string)[][] }[] = constraints2030.map((x) => ({
         id: x.name,
         keys: allPoliciesNull ? CONSTRAINTS_2030_KEYS.slice(0, 2) : CONSTRAINTS_2030_KEYS,
         allValues: allPoliciesNull
            ? [[x.numberOfLimitingHours_contractCase_study2023 ?? '-', x.numberOfLimitingHours_contractCase_study2021 ?? '-']]
            : [[x.numberOfLimitingHours_contractCase_study2023 ?? '-', x.numberOfLimitingHours_contractCase_study2021 ?? '-', x.numberOfLimitingHours_policyCaseS1_study2021 ?? '-']],
      }));
      return rows;
   }, [constraints2030]);

   const constraints2030Rows = useMemo(() => {
      const rows: { id: string; keys: string[]; allValues: (number | string)[][] }[] = constraints2035.map((x) => ({
         id: x.name,
         keys: CONSTRAINTS_2035_KEYS,
         allValues: [[x.numberOfLimitingHours_lowerDemand_study2023 ?? '-', x.numberOfLimitingHours_higherDemand_study2023 ?? '-']],
      }));
      return rows;
   }, [constraints2035]);

   // Info
   const info2030: Info2030[] = useMemo(() => getInfos2030(infos.find(x => x.target_year === 2030)?.info ?? []), [infos]);

   const info2035: Info2035[] = useMemo(() => getInfos2035(infos.find(x => x.target_year === 2035)?.info ?? []), [infos]);

   const info2030Rows = useMemo(() => {
      const rows: { id: string; keys: string[]; allValues: (number | string)[][] }[] = info2030.filter(x => x.generation_pocket_id === selectedPocket).map((x) => ({
         id: x.type,
         keys: INFO_2030_KEYS,
         allValues: [
            [x.capacity_contractCase_study2023 ?? '-', x.capacity_contractCase_study2021 ?? '-'],
            [x.energy_deliverability_contractCase_study2023 ?? '-', x.energy_deliverability_contractCase_study2021 ?? '-'],
         ],
      }));
      return rows;
   }, [info2030, selectedPocket]);

   const info2035Rows = useMemo(() => {
      const rows: { id: string; keys: string[]; allValues: (number | string)[][] }[] = info2035.filter(x => x.generation_pocket_id === selectedPocket).map((x) => ({
         id: x.type,
         keys: INFO_2035_KEYS,
         allValues: [
            [x.capacity_contractcase_study2023 ?? '', x.capacity_lowerDemand_study2023 ?? '-', x.capacity_higherDemand_study2023 ?? '-'],
            [x.energy_deliverability_contractcase_study2023 ?? '', x.energy_deliverability_lowerDemand_study2023 ?? '-', x.energy_deliverability_higherDemand_study2023 ?? '-'],
         ],
      }));
      return rows;
   }, [info2035, selectedPocket]);

   const onPocketRadioClick = (newSelectedPocket: number | null) => {
      if (newSelectedPocket === selectedPocket) {
         setSelectedPocket(null);
      } else {
         setSelectedPocket(newSelectedPocket);
      }
   };

   return (
      <div className={classes.sider}>
         <Flex justify="center">
            <Checkbox checked={showAllPockets} onChange={(e) => setShowAllPockets(e.target.checked)}>
               <h4 style={{ margin: '8px 0' }}>Show All Pockets on map</h4>
            </Checkbox>
         </Flex>
         <Flex justify="center" style={{ width: '100%', padding: '12px 18px', position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white', borderBottom: '1px solid #f0f0f0' }}>
            <Radio.Group size="middle" value={selectedPocket} /* onChange={(e) => onChangePocket(e.target.value)} */>
               <Space direction="vertical" style={{ width: '100%', textAlign: 'center' }}>
                  <div style={{ fontSize: 16, fontWeight: 600 }}>Renewable Resource Regions</div>
                  <div style={{ fontSize: 12, fontWeight: 500 }}>
                     <Flex justify="space-between" style={{ padding: '0px 14px 2px 12px ' }}>
                        <div>Western New York</div>
                        <div> North Country</div>
                        <div>Capital Region</div>
                        <div>Southern Tier</div>
                     </Flex>

                     <Flex justify="space-between">
                        <Flex justify="center">
                           {pockets
                              .filter((x) => x.name.startsWith('W'))
                              .map((x) => (
                                 <Radio.Button key={x.id} value={x.id} onClick={() => onPocketRadioClick(x.id)}>
                                    {x.name}
                                 </Radio.Button>
                              ))}
                        </Flex>

                        <div style={{ margin: 8 }}></div>
                        <Flex justify="center">
                           {pockets
                              .filter((x) => x.name.startsWith('X'))
                              .map((x) => (
                                 <Radio.Button key={x.id} value={x.id} onClick={() => onPocketRadioClick(x.id)}>
                                    {x.name}
                                 </Radio.Button>
                              ))}
                        </Flex>

                        <div style={{ margin: 8 }}></div>
                        <Flex vertical justify="space-between">
                           <Flex justify="center">
                              {pockets
                                 .filter((x) => x.name.startsWith('Y'))
                                 .map((x) => (
                                    <Radio.Button key={x.id} value={x.id} onClick={() => onPocketRadioClick(x.id)}>
                                       {x.name}
                                    </Radio.Button>
                                 ))}
                           </Flex>
                        </Flex>

                        <div style={{ margin: 8 }}></div>
                        <Flex justify="center">
                           {pockets
                              .filter((x) => x.name.startsWith('Z'))
                              .map((x) => (
                                 <Radio.Button key={x.id} value={x.id} onClick={() => onPocketRadioClick(x.id)}>
                                    {x.name}
                                 </Radio.Button>
                              ))}
                        </Flex>
                     </Flex>
                  </div>
               </Space>
            </Radio.Group>
         </Flex>
         <div style={{ padding: '12px 8px' }}>
            {
               <Flex justify="center" style={{ fontSize: 14, fontWeight: 600, margin: 8, /* border: '1px solid #000000', */ borderRadius: 6, width: '96%', textAlign: 'center', padding: 6, backgroundColor: '#42b389', color: '#ffffff' }}>
                  <div style={{ width: '100%' }}>
                     <Checkbox
                        autoFocus checked={gpLayer.year2030} onChange={(e) => setGpLayer((prev) => prev && { ...prev, year2030: e.target.checked })}
                        style={{ width: '100%', color: '#ffffff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                2030
                     </Checkbox>
                  </div>
               </Flex>
            }
            {gpLayer.year2030 && (
               <>
                  <TableConstraint headers={CONSTRAINTS_2030_HEADERS} keys={constraint2030Rows.length ? constraint2030Rows[0].keys : []} rows={constraint2030Rows} />
                  <TableInfo headers={INFO_2030_HEADERS} keys={info2030Rows.length ? info2030Rows[0].keys : []} rows={info2030Rows} />
               </>
            )}

            {
               <Flex justify="center" style={{ fontSize: 14, fontWeight: 600, margin: 8, /* border: '1px solid #000000', */ borderRadius: 6, width: '96%', textAlign: 'center', padding: 6, backgroundColor: '#42b389', color: '#ffffff' }}>
                  <div style={{ width: '100%' }}>
                     <Checkbox checked={gpLayer.year2035} onChange={(e) => setGpLayer((prev) => prev && { ...prev, year2035: e.target.checked })} style={{ width: '100%', color: '#ffffff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                2035
                     </Checkbox>
                  </div>
               </Flex>
            }
            {gpLayer.year2035 && (
               <>
                  <TableConstraint headers={CONSTRAINTS_2035_HEADERS} keys={constraints2030Rows.length ? constraints2030Rows[0].keys : []} rows={constraints2030Rows} />
                  <TableInfo headers={INFO_2035_HEADERS} keys={info2035Rows.length ? info2035Rows[0].keys : []} rows={info2035Rows} />
               </>
            )}
         </div>
      </div>
   );
};
