import { GenerationPocketConstraint, GenerationPocketInfo } from 'power/types';

export type Constraint2030 = {
  id: string;
  name: string;
  numberOfLimitingHours_contractCase_study2023: number | null;
  numberOfLimitingHours_contractCase_study2021: number | null;
  numberOfLimitingHours_policyCaseS1_study2021: number | null;
};

export type Constraint2035 = {
  id: string;
  name: string;
  numberOfLimitingHours_lowerDemand_study2023: number | null;
  numberOfLimitingHours_higherDemand_study2023: number | null;
};

export const getConstraints2030 = (constraints: GenerationPocketConstraint[]): Constraint2030[] => {
   const data: Constraint2030[] = [];

   constraints.forEach((x) => {
      let numberOfLimitingHours_contractCase_study2023: number | null = null;
      let numberOfLimitingHours_contractCase_study2021: number | null = null;
      let numberOfLimitingHours_policyCaseS1_study2021: number | null = null;

      x.number_of_limiting_hours.forEach((y) => {
         if (y.study_year === 2023) {
            if (y.case_type === 'ContractCase') {
               numberOfLimitingHours_contractCase_study2023 = y.value;
            }
         } else if (y.study_year === 2021) {
            if (y.case_type === 'ContractCase') {
               numberOfLimitingHours_contractCase_study2021 = y.value;
            } else if (y.case_type === 'PolicyCaseS1') {
               numberOfLimitingHours_policyCaseS1_study2021 = y.value;
            }
         }
      });

      data.push({
         id: x.name,
         name: x.name,
         numberOfLimitingHours_contractCase_study2023,
         numberOfLimitingHours_contractCase_study2021,
         numberOfLimitingHours_policyCaseS1_study2021,
      });
   });

   return data;
};

export const getConstraints2035 = (constraints: GenerationPocketConstraint[]): Constraint2035[] => {
   const data: Constraint2035[] = [];

   constraints.forEach((x) => {
      let numberOfLimitingHours_lowerDemand_study2023: number | null = null;
      let numberOfLimitingHours_higherDemand_study2023: number | null = null;

      x.number_of_limiting_hours.forEach((y) => {
         if (y.study_year === 2023) {
            if (y.case_type === 'PolicyCaseLowerDemand') {
               numberOfLimitingHours_lowerDemand_study2023 = y.value;
            } else if (y.case_type === 'PolicyCaseHigherDemand') {
               numberOfLimitingHours_higherDemand_study2023 = y.value;
            }
         }
      });

      data.push({
         id: x.name,
         name: x.name,
         numberOfLimitingHours_lowerDemand_study2023,
         numberOfLimitingHours_higherDemand_study2023,
      });
   });

   return data;
};

export type Info2030 = {
  generation_pocket_id: number;
  type: 'Solar' | 'Wind' | 'Hydro' | 'HQ Imports';
  capacity_contractCase_study2023: number | null;
  capacity_contractCase_study2021: number | null;
  energy_deliverability_contractCase_study2023: number | null;
  energy_deliverability_contractCase_study2021: number | null;
};

export type Info2035 = {
  generation_pocket_id: number;
  type: 'Solar' | 'Wind' | 'Hydro' | 'HQ Imports';
  capacity_contractcase_study2023: number | null;
  capacity_lowerDemand_study2023: number | null;
  capacity_higherDemand_study2023: number | null;
  energy_deliverability_contractcase_study2023: number | null;
  energy_deliverability_lowerDemand_study2023: number | null;
  energy_deliverability_higherDemand_study2023: number | null;
};

export const getInfos2030 = (infos: GenerationPocketInfo[]): Info2030[] => {
   const data: Info2030[] = infos.map((x) => ({
      generation_pocket_id: x.generation_pocket_id,
      type: x.type,
      capacity_contractCase_study2023: x.capacities.find((y) => y.study_year === 2023 && y.case_type === 'ContractCase')?.value ?? null,
      capacity_contractCase_study2021: x.capacities.find((y) => y.study_year === 2021 && y.case_type === 'ContractCase')?.value ?? null,
      energy_deliverability_contractCase_study2023: x.energy_deliverability.find((y) => y.study_year === 2023 && y.case_type === 'ContractCase')?.value ?? null,
      energy_deliverability_contractCase_study2021: x.energy_deliverability.find((y) => y.study_year === 2021 && y.case_type === 'ContractCase')?.value ?? null,
   }));

   return data;
};

export const getInfos2035 = (infos: GenerationPocketInfo[]): Info2035[] => {
   const data: Info2035[] = infos.map((x) => ({
      generation_pocket_id: x.generation_pocket_id,
      type: x.type,
      capacity_contractcase_study2023: x.capacities.find((y) => y.study_year === 2023 && y.case_type === 'ContractCase')?.value ?? null,
      capacity_lowerDemand_study2023: x.capacities.find((y) => y.study_year === 2023 && y.case_type === 'PolicyCaseLowerDemand')?.value ?? null,
      capacity_higherDemand_study2023: x.capacities.find((y) => y.study_year === 2023 && y.case_type === 'PolicyCaseHigherDemand')?.value ?? null,
      energy_deliverability_contractcase_study2023: x.energy_deliverability.find((y) => y.study_year === 2023 && y.case_type === 'ContractCase')?.value ?? null,
      energy_deliverability_lowerDemand_study2023: x.energy_deliverability.find((y) => y.study_year === 2023 && y.case_type === 'PolicyCaseLowerDemand')?.value ?? null,
      energy_deliverability_higherDemand_study2023: x.energy_deliverability.find((y) => y.study_year === 2023 && y.case_type === 'PolicyCaseHigherDemand')?.value ?? null,
   }));

   return data;
};
