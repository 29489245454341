import { GenerationPocketTransmissionLine } from 'power/types';
import React, { useEffect, useMemo } from 'react';
import { Layer, Source, useMap } from 'react-map-gl';
import { getTransmissionLinesGeojson } from './helper';

const LAYER_ID = 'GENERATION_POCKETS_TRANSMISSION_LINES';

type Props = {
  mapId: string;
  transmissionLines: GenerationPocketTransmissionLine[];
  selectedPocket: number | null;
  show2035: boolean;
  show2030: boolean;
  showAllPockets: boolean;
};

const TransmissionLinesLayer: React.FC<Props> = ({ mapId, transmissionLines, selectedPocket, show2030, show2035, showAllPockets }) => {
   const { [mapId]: map } = useMap();

   const filter = useMemo(() => {
      if (showAllPockets || selectedPocket === null) return ['all'];
      return ['==', ['get', 'generation_pocket_id'], selectedPocket];
   }, [selectedPocket, showAllPockets]);

   const geojson = useMemo(() => getTransmissionLinesGeojson(transmissionLines), [transmissionLines]);

   /* useEffect(() => {
      if (!map) return;

      map.on('click', (e) => {
         const features = map.queryRenderedFeatures(e.point, { layers: [LAYER_ID] });
         if (features.length) {
            const id = features[0].properties?.id;
            setSelectedPocket(id);
         }
      });
   }, [map, setSelectedPocket]);

    */

   useEffect(() => {
      if (!map) return;

      // set cursor to pointer when hovering over pocket
      map.on('mousemove', (e) => {
         const features = map.queryRenderedFeatures(e.point, { layers: [LAYER_ID] });
         map.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });
   }, [map]);

   if (!map) return null;

   return (
      <>
         <Source id={LAYER_ID} type="geojson" data={geojson}>
            <Layer
               id={LAYER_ID} type="line" paint={{ 'line-color': '#28ff1e', 'line-dasharray': [2, 1], 'line-width': 4 }}
               filter={filter} />
         </Source>
      </>
   );
};

export default TransmissionLinesLayer;
